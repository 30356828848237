.coverImage img {
  width: 100%;
  min-height: 100%;
}

@media screen and (max-width: 600px) {
  .coverImage img {
    height: 300px;
  }
  
}
