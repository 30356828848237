#footerContainer {
  
  ::placeholder {
    font-family: 'Raleway', sans-serif;
  }
  
  *{
    box-sizing: border-box;
  }
  
  
  p {
    margin: 0;
    font-weight: 200;
  }
  
  h2 {
    font-size: 36px;
    font-weight: 200;
  }
  
  button, span, input {
    font-weight: 200;
  }

  #enhanced {
    span {
      font-size: larger;
    }

  }
  
  
  @media screen and (max-width: 963px) {
  
  }
  
  @media screen and (max-width: 600px) {
    p {
      font-size: 15px;
    }
  
    h2 {
      font-size: 26px !important;
    }
  }
}
