#headerContainer {
  
  ::placeholder {
    font-family: 'Raleway', sans-serif;
  }
  
  *{
    box-sizing: border-box;
  }
}
