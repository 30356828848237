@import "~react-dropzone-uploader/dist/styles.css";

.dzu-dropzone {
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 80px;

  .dzu-previewContainer {
    padding: 15px 0 15px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: min-content;
    min-height: 60px;
    z-index: 1;
    border-bottom: 1px solid #ECECEC;
    box-sizing: border-box;

    &:last-child {
      padding-right: 15px;
    }
  }

  .dzu-inputLabel {
    margin-bottom: 0;
  }

  .dzu-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 0 14px;
    min-height: 32px;
    background-color: #E6E6E6;
    color: #2484FF;
    border: none;
    font-family: 'Helvetica', sans-serif;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    flex: 1 1 auto;
    width: 100%;
    margin: 15px 10px 10px;
  }

  .dzu-submitButtonContainer {
    margin: 0 10px 10px;
    z-index: 1;
    flex: 1 1 auto;
    width: 100%;

    .dzu-submitButton {
      width: 100%;
    }
  }
}

.store-image-modal {
  .image-section {
    height: 150px;
    background-color: #e2e2e2;
    margin-bottom: 15px;
    overflow-x: scroll !important;
    padding: 10px;
    white-space: nowrap;
    display: flex;

    .image-wrapper {
      margin-right: 10px;
      display: inline-block;
      position: relative;
      height: 100%;
      min-width: max-content;
      background-color: #f1f1f1;

      img {
        height: 100%;
        min-width: 100px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: background 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-remove {
        opacity: 0;
        padding: 8px 10px;
        line-height: 0;
        border-radius: 50%;
        transition: all .35s ease;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .overlay {
          display: flex;
          background: rgba(0, 0, 0, .3);
        }

        .btn-remove {
          opacity: 1;
          height: 30px;
          width: 30px;
          padding: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
