.table-avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;
  border: 2px solid $primary;
  img {
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    font-size: 22px;
    font-weight: 500;
    color: white;
  }
}

.table-avatar-lg {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;
  border: 2px solid $primary;
  img {
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    font-size: 22px;
    font-weight: 500;
    color: white;
  }
}
