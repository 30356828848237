.navbar {
  height: 98px;
  width: 100%;
  padding: 0px 100px;
}

.navbarInner {
  display: grid;
  grid-template-columns:
    max-content
    1fr max-content;
  grid-template-rows: 100%;
  height: 100%;
}

.navLinksArea,
.accountArea,
.logoArea {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* logo */

.logoCont img {
  max-width: 92px;
  height: auto;
}

/* list */

.accountActionsInner {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  grid-gap: 0px 40px;
}

.accountActions ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 30px;
}

.accountActions ul li {
}

.accountActions ul li a {
  color: #505155;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
}

.signupBtn {
  border: 1px solid #2db75b;
  padding: 10px 20px;
  color: #2db75b;
  cursor: pointer;
}


.signupBtn a {
  text-decoration: none;
  color: #2db75b;
  font-size: 16px;
  font-weight: 300;
}


.loginBtn {
  cursor: pointer;
  color: #0a0d27;
}


.loginBtn a {
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #0a0d27;
}


.navbarToggle {
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbarToggle img {
  width: 100%;
}

@media screen and (max-width: 963px) {
  .navbar {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 600px) {
  .signupBtn {
    padding: 8px 16px;
  }

  .accountActionsInner {
    grid-gap: 0px 20px;
  }

  .navbarInner {
    grid-template-columns:
      max-content
      max-content 1fr;
  }

  .logoCont img {
    max-width: 52px;
  }

  .accountArea {
    justify-content: flex-end;
  }

  .navbar {
    height: 78px;
  }



  .signupBtn {
    font-size: 12px;
    padding: 8px;
  }
  
  .loginBtn {
    font-size: 12px;
  }

  
}
