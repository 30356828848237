.footerCont {
  background-color: #0a0d27;
  color: #ffffff;
}

.footerContInner {
  padding: 70px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerCont p {
}

.infoBlock {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.block1,
.block2,
.block3,
.block4 {
  max-width: 270px;
}

.block3 {
  color: #D6D6D6;
}

.block1 span {
  font-size: 12px;
  color: #D6D6D6;
}

.block2 ul {
  list-style-type: none;
}

.block2 ul li {
  margin-bottom: 15px;
}


.block2 ul li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}



.block12,
.block34 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 50%;
}

.logoCont {
  margin-bottom: 20px;
}

.logoCont img {
  max-width: 92px;
  height: auto;
}

.socialIcons {
  display: flex;
  flex-flow: row wrap;
}

.socialIcons .icon {
  margin-top: 15px;
  margin-right: 15px;
}

.contactUs p,
.followUs p {
  font-size: 20px;
  color: rgba(255, 255, 255,1);
}

.copyRightBlock {
  background-color: #2db75b;
  text-align: center;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyRightBlock p {
  font-size: 12px;
}


.introStm span {
  font-size: 12.2px;
}

@media screen and (max-width: 963px) {
  .block12,
  .block34 {
    width: 100%;
  }

  .block34 {
    border-top: 2px solid rgba(255, 255, 255, 1);
    margin-top: 30px;
  }

  .block2 {
    margin-left: 30px;
  }

  .block4 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  .block12,
  .block34 {
    flex-flow: row wrap;
    justify-content: flex-start;
    border: 0px;
  }

  .block2 ul {
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 45px;
  }


  .block2 {
    margin-left: 0px;
  }

  .block4 {
    margin-left: 0px;
  }

  .block1, .block2, .block3, .block4 {
    margin-top: 10px;
  }
}
