.pricing-table {
	// background-color: #eee;
	.block-heading {
		padding-top: 10px;
		margin-bottom: 40px;
		text-align: center;
		h2 {
			color: #3b99e0;
		}
		p {
			text-align: center;
			max-width: 420px;
			margin: auto;
			opacity: 0.7;
		}
	}
	.heading {
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
	.item {
		height: 500px;
		background-color: #ffffff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
		border-top: 2px solid #5ea4f3;
		padding: 30px;
		overflow: hidden;
		position: relative;
		button {
			font-weight: 600;
		}
		p {
			overflow-y: scroll;
			height: 40px;
			text-align: center;
			margin-top: 20px;
			opacity: 0.7;
		}
	}
	.col-md-5 {
		&:not(:last-child) {
			.item {
				margin-bottom: 30px;
			}
		}
	}
	.ribbon {
		width: 160px;
		height: 32px;
		font-size: 12px;
		text-align: center;
		color: #fff;
		font-weight: bold;
		box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
		background: #4dbe3b;
		transform: rotate(45deg);
		position: absolute;
		right: -42px;
		top: 20px;
		padding-top: 7px;
	}
	.features {
		.feature {
			font-weight: 600;
		}
		h4 {
			text-align: center;
			font-size: 18px;
			padding: 5px;
		}
	}
	.price {
		h4 {
			margin: 15px 0;
			font-size: 45px;
			text-align: center;
			color: #2288f9;
		}
	}
	.pricing {
		position: absolute;
		width: calc(100% - 40px);
		bottom: 20px;
		margin: auto;
		left: 0px;
		right: 0px;

		button {
			text-align: center;
			margin: auto;
			font-weight: 600;
			padding: 9px 0;
		}
	}

	.toggler {
		.switch {
			position: relative;
			display: inline-block;
			width: 45px;
			height: 24px;
		}

		/* Hide default HTML checkbox */
		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 3px;
			bottom: 2px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			background-color: #2196f3;
		}

		input:focus + .slider {
			box-shadow: 0 0 1px #2196f3;
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(20px);
			-ms-transform: translateX(20px);
			transform: translateX(20px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 34px;
		}

		.slider.round:before {
			border-radius: 50%;
		}
	}
}
