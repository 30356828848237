.navbarCont {
  width: 500px;
  height: 100%;
  background: #E9EBEE;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px;
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  top: 0px;
  overflow: auto;
  z-index: 1;
}

.navbarLogoCont {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
}

.navbarLogoCont a {
  font-family: var(--primary-font-family);
  text-decoration: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  place-content: space-between;
}

.navbarLogoImage {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.navbarLogoImage img {
  width: 100%;
  height: auto;
}

.navbarLogoCont .closeIcon {
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
}

.navbarLogoCont .closeIcon svg {
  width: 28px;
  height: 28px;
  fill:rgba(10, 13, 39, 1)
}

.navbarLogoImage {
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
    align-items: center;
}

.navbarLogoText {
  color: rgba(10, 13, 39, 1);
  font-size: 20px;
}

.myAppTablesListCont {
  height: 200px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  max-width: 270px;
  padding: 20px 0px;
}

.myAppTablesListHeading {
}

.myAppTablesListHeading .mainHeading {
  text-align: var(--text-alignment);
  direction: var(--text-direction);
  font: normal normal 600 22px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

.myAppTablesListHeading .noOfTables {
  text-align: var(--text-alignment);
  direction: var(--text-direction);
  font: normal normal 400 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

.myAppTablesList {
  height: 150px;
  width: 100%;
  overflow: auto;
  margin-top: 20px;
}

.myAppTablesList ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.myAppTablesList ul li {
  padding: 10px 0px;
}

.myAppTablesList ul li span {
  font: normal normal 400 22px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

.navbarLinks {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  width: 100%;
}

.navbarLinks ul {
  padding: 0px;
  margin: 0px;
}

.navbarLinks li:not(:last-child) {
  list-style-type: none;
  border-bottom: 2px solid rgba(10, 13, 39, 0.4);
}

.navbarLinks li:last-child {
  list-style-type: none;
}

.navbarLinks ul li a {
  text-decoration: none;
  padding: 30px 8px;
  font: normal normal 300 22px/27px Montserrat;
  letter-spacing: 0px;
  color: rgba(10, 13, 39, 1);
  display: block;
}

.logoutBtnCont {
  text-decoration: none;
  font: normal normal 300 22px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  width: 100%;
  border-top: 2px solid rgba(10, 13, 39, 0.4);
}

.logoutBtnCont a {
  font: normal normal 300 22px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  padding: 40px 8px;
  display: block;
}

.navbarCont::-webkit-scrollbar {
  width: 8px;
}

.navbarCont::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255, 0.2);
}

.navbarCont::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255, 0.7);
}

.myAppTablesList::-webkit-scrollbar {
  width: 8px;
}

.myAppTablesList::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255, 0.2);
}

.myAppTablesList::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255, 0.7);
}

@media screen and (max-width: 1440px) {
  .navbarCont {
    width: 280px;
  }

  .navbarLinks ul li a {
    font: normal normal 300 16px/10px var(--primary-font-family);
  }

  .logoutBtnCont a {
    font: normal normal 300 16px/10px var(--primary-font-family);
  }

  .myAppTablesList ul li span {
    font: normal normal 400 16px/10px var(--primary-font-family);
  }

  .myAppTablesListHeading .mainHeading {
    font: normal normal 600 18px/19px var(--primary-font-family);
  }
}
