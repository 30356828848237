.base {
  padding-right: 4px;
}

.board {
  display: grid;
  grid-gap: 10px;
  grid-template-rows: max-content auto;
}

.board-header {
  color: black;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.board-header-text {
  font-weight: bold;
  padding: 5px;
}

.board-header .left {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 10px;
  justify-self: left;
}

.board-header .right {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 10px;
  justify-self: right;
}

.board-lists {
  display: grid;
  grid-auto-columns: 320px;
  grid-auto-flow: column;
  grid-gap: 15px;
  overflow: scroll;
}

.board-list {
  background-color: rgb(232, 232, 232) !important;
  border-radius: 5px;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 20px;
  height: max-content;
  padding: 10px;
}

.list-title {
  text-align: center;
  font-size: larger;
  font-weight: 600;
}

.card-new {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 10px;
  padding-inline: 15px;
  margin-bottom: 10px !important;
}

.add-card {
  padding-top: 10px;
  padding-bottom: 5px;
}

.items-holder {
  overflow: scroll;
  max-height: 50vh;
}

.bg-container {
  padding: 7px;
  padding-inline: 15px;
  background-color: #2D9CDB
}
